import gql from 'graphql-tag';

export const GET_FLEET_KPIS = gql`
  query GetFleetKpis(
    $startDate: DateTime!
    $endDate: DateTime!
    $vesselImos: [String!]
  ) {
    fleet(
      vesselImos: $vesselImos
      dateRange: { startDateTime: $startDate, endDateTime: $endDate }
    ) {
      id
      # TODO: Remove once GQL is updated to make this optional / removed
      kpis(dateRange: { startDateTime: $startDate, endDateTime: $endDate }) {
        eua {
          value
          status
          liability
          planned
        }
        eeoi {
          actual
          deviation
          status
          target
        }
        fuelEU {
          wellToTank
          tankToWake
          wellToWake
          wellToWakeStatus
          windRewardFactor
          ghgIntensity
          ghgIntensityTarget
          ghgIntensityStatus
          complianceBalance
          fleetComplianceBalance
          fuelPenalty
          fleetFuelPenalty
          fuelPenaltyAgainstFleetPercentage
          onShorePowerSupply
          fuelUsage {
            classification
            total
            consumptions {
              name
              consumptionMt
            }
          }
        }
        ghgMrv {
          emissionsByYear {
            value
            key {
              year
              emission
            }
          }
          fuelSlippageByYear {
            key
            value
          }
          ghgByYear {
            key
            value
          }
        }
      }
    }
  }
`;

export const GET_FLEET_VESSELS = gql`
  query GetFleetVessels(
    $startDate: DateTime!
    $endDate: DateTime!
    $vesselImos: [String!]
  ) {
    fleet(
      vesselImos: $vesselImos
      dateRange: { startDateTime: $startDate, endDateTime: $endDate }
    ) {
      id
      environmentalMonitorVessels {
        id
        vesselId
        sisterClass
        name
        kpis {
          aer {
            required
            attained
            deviation
            status
            delta
          }
          cii {
            required
            ciiRating
            attained
            deviation
            status
            delta
          }
          ciiPercentage {
            status
            deviation
          }
          eeoi {
            target
            actual
            deviation
            status
            delta
          }
          euaAgainstFleet {
            vessel
            value
            fleet
            status
          }
          euaAgainstPlanned {
            value
            vesselEuaCo2
            vesselEuaPlannedValue
            vesselEuaLiability
            status
            delta
          }
          fuelEU {
            wellToTank
            tankToWake
            wellToWake
            wellToWakeStatus
            complianceBalance
            fuelPenalty
            fuelPenaltyAgainstFleetPercentage
          }
          ghgMrv {
            emissionsByYear {
              value
              key {
                year
                emission
              }
            }
            fuelSlippageByYear {
              key
              value
            }
            ghgByYear {
              key
              value
            }
          }
        }
        conditionSummary {
          fuelConsumed
          carbonEmitted
          distanceTravelled
        }
      }
    }
  }
`;

export const GET_FLEET_UTILIZATION = gql`
  query GetFleetUtilization(
    $startDate: DateTime!
    $endDate: DateTime!
    $vesselImos: [String!]
  ) {
    fleet(
      vesselImos: $vesselImos
      dateRange: { startDateTime: $startDate, endDateTime: $endDate }
    ) {
      id
      fleetUtilization(
        startDate: $startDate
        endDate: $endDate
        vesselImos: $vesselImos
      ) {
        atSea {
          duration
          count
          countPercentage
          durationPercentage
        }
        idle {
          duration
          count
          countPercentage
          durationPercentage
        }
        inPort {
          duration
          count
          countPercentage
          durationPercentage
        }
        ballast {
          duration
          count
          countPercentage
          durationPercentage
        }
        laden {
          duration
          count
          countPercentage
          durationPercentage
        }
        maneuvering {
          duration
          count
          countPercentage
          durationPercentage
        }
      }
    }
    vessels(
      vesselImos: $vesselImos
      dateRange: { startDateTime: $startDate, endDateTime: $endDate }
    ) {
      id
      name
      imoNumber
      kpis(startDate: $startDate, endDate: $endDate) {
        cii {
          ciiRating
          status
        }
        utilization {
          atSea {
            count
            countPercentage
            duration
            durationPercentage
          }
          ballast {
            count
            countPercentage
            duration
            durationPercentage
          }
          idle {
            count
            countPercentage
            duration
            durationPercentage
          }
          inPort {
            count
            countPercentage
            duration
            durationPercentage
          }
          laden {
            count
            countPercentage
            duration
            durationPercentage
          }
          maneuvering {
            duration
            count
            countPercentage
            durationPercentage
          }
        }
      }
    }
  }
`;

export const GET_FLEET_EEOI_CHART_DATA = gql`
  query GetFleetEeoiChart(
    $startDate: DateTime!
    $endDate: DateTime!
    $vesselImos: [String!]
  ) {
    fleet(
      dateRange: { startDateTime: $startDate, endDateTime: $endDate }
      vesselImos: $vesselImos
    ) {
      eeoiMonthlyValues(startDate: $startDate, endDate: $endDate) {
        date
        deviation
        voyageLegsCompleted
      }
    }
  }
`;

export const GET_FLEET_CII_MONTHLY_RATINGS = gql`
  query GetFleetCIIMonthlyRatings(
    $startDate: DateTime!
    $endDate: DateTime!
    $vesselImos: [String!]
  ) {
    fleet(
      dateRange: { startDateTime: $startDate, endDateTime: $endDate }
      vesselImos: $vesselImos
    ) {
      id
      # TODO: Remove start, end and use fleet level date range
      ciiMonthlyRatings(startDate: $startDate, endDate: $endDate) {
        month
        year
        actual
        ratingCount {
          a
          b
          c
          d
          e
        }
      }
    }
  }
`;

export const GET_FLEET_FUELEU_GHG_INTENSITY_CHART_DATA = gql`
  query GetFleetFuelEuGhgIntensityChart(
    $startDate: DateTime!
    $endDate: DateTime!
    $vesselImos: [String!]
  ) {
    fleet(
      vesselImos: $vesselImos
      dateRange: { startDateTime: $startDate, endDateTime: $endDate }
    ) {
      id
      fuelGHGIntensity(
        dateRange: { startDateTime: $startDate, endDateTime: $endDate }
      ) {
        ghgIntensity {
          date
          isFutureProjection
          intensityTarget
          intensity
          complianceBalance
          fuelEUPenalty
          fuelUsage {
            classification
            consumptions {
              name
              consumptionMt
            }
          }
        }
      }
    }
  }
`;
